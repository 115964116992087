import React from "react"

import Layout from '../../components/layout'
import Head from '../../components/head'
import TeamsTabbed from "../../components/teams_tabbed"

const Teams = () => {


    return (
        <Layout>
            <Head
                title="NFL Win Totals"
                pageDescription="Historical win totals and progress against current win totals for NFL teams."
            />
            <TeamsTabbed
                activeIndex={4}
            />
        </Layout>
    )
}

export default Teams

